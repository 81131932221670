.loader-button-image {
  margin: 0 0 5px 8px;
  height: 22px;
  animation: spin 1s linear infinite;
  max-width: none;
  position: absolute;
  left: calc(50% - 20px);
  bottom: 0;

  &.large {
    height: 26px;
  }

  &.small {
    height: 16px;
    left: calc(50% - 16px);
  }
}

.loader-button-image__text {
  height: 22px;
  animation: spin 1s linear infinite;
  max-width: none;
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.loader-button-container {
  position: relative;
  margin-bottom: -0.75rem !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
