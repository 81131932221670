:root {
  --euroaccident: #c6007e;
  --mindoktor: #e52427;
}

:root[data-theme-variant='light'] {
  --body-background: rgb(242, 242, 242);
  --primary-text: rgb(36, 36, 36);
  --input-border-color: rgb(204, 204, 204);
  --input-background-color: rgb(255, 255, 255);
  --input-text: rgb(36, 36, 36);

  --card-background-color: rgb(255, 255, 255);
  --card-header-border-color: rgb(230, 230, 230);
  --search-results-count-color: rgb(128, 128, 128);
  --table-row-color: rgb(255, 255, 255);
  --table-row-color-hover: rgb(247, 249, 250);
  --table-header-color: rgb(250, 250, 250);
  --table-border-color: rgb(235, 235, 235);
  --table-cell-color: rgb(36, 36, 36);

  --hint-text: rgb(88, 109, 134);
  --hr-color: rgb(235, 235, 235);
  --button-disabled-background-color: rgb(167, 167, 167);
  --dropdown-item-hover-background-color: rgba(62, 105, 184, 0.1);
  --dropdown-item-active-background-color: rgba(62, 105, 184, 0.15);
  --error-primary: rgb(218, 30, 40);
  --error-primary-raw-rgb: 218, 30, 40;
  --error-background: rgb(255, 240, 241);
  --base: rgb(24, 35, 55);
  --primary: rgb(48, 96, 191);
  --primary-raw-rgb: 48, 96, 191;
  --secondary: rgb(234, 239, 248);
  --error-background: rgb(255, 230, 230);
  --accumbo-brand-color: rgb(114, 5, 58);
  --accumbo-brand-text-color: rgb(250, 233, 238);
  --modal-footer-background-color: rgb(243, 245, 248);

  --editable-text-highlight-color-start: rgba(46, 58, 68, 0.7);
  --editable-text-highlight-color-end: rgba(1, 87, 155, 0);

  --login-background-color: rgb(252, 251, 252);

  --data-missing: rgb(103, 103, 103);

  --checkbox-item-color: rgb(36, 36, 36);
  --checkbox-item-background-color: rgb(251, 251, 251);
  --checkbox-item-background-hover-color: rgb(245, 245, 245);

  --button-tertiary-color: rgb(55, 99, 42);
  --button-tertiary-background: rgb(237, 248, 234);

  --sidebar-link-color: rgb(46, 46, 46);
  --sidebar-link-color-disabled: rgb(175, 175, 175);
  --sidebar-link-color-active: rgba(0, 104, 255, 0.1);

  --card-description-color: rgb(89, 89, 89);

  --status-created-background: rgb(255, 249, 228);
  --status-pending-background: rgb(255, 249, 228);
  --status-exemption-background: rgb(234, 242, 238);
  --status-complete-background: rgb(234, 242, 238);
  --status-failed-background: rgb(255, 240, 241);
  --status-created-color: rgb(240, 163, 32);
  --status-pending-color: rgb(240, 163, 32);
  --status-exemption-color: rgb(51, 128, 89);
  --status-complete-color: rgb(51, 128, 89);
  --status-failed-color: rgb(218, 30, 40);

  --dropdown-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);

  --card-tab-link-color: rgb(101, 101, 101);
}

:root[data-theme-variant='dark'] {
  --body-background: rgb(24, 30, 36);
  --primary-text: rgb(163, 174, 185);
  --input-border-color: rgb(112, 112, 112);
  --input-background-color: rgb(192, 199, 206);
  --input-text: rgb(36, 36, 36);

  --card-background-color: rgb(31, 40, 49);
  --card-header-border-color: rgb(24, 32, 39);

  --search-results-count-color: rgb(243, 243, 243);
  --table-row-color: rgb(31, 40, 49);
  --table-row-color-hover: rgb(36, 44, 54);
  --table-header-color: rgb(36, 45, 54);
  --table-border-color: rgb(46, 46, 46);
  --table-cell-color: rgb(222, 229, 238);

  --hint-text: rgb(126, 152, 185);
  --hr-color: rgb(24, 32, 39);
  --button-disabled-background-color: rgb(58, 58, 58);
  --dropdown-item-hover-background-color: rgba(62, 105, 184, 0.1);
  --dropdown-item-active-background-color: rgba(62, 105, 184, 0.15);
  --error-primary: rgba(255, 38, 38, 0.692);
  --error-primary-raw-rgb: 218, 30, 40;
  --error-background: rgb(255, 240, 241);
  --base: rgb(24, 35, 55);
  --primary: rgb(75, 127, 230);
  --primary-raw-rgb: 48, 96, 191;
  --secondary: rgb(163, 184, 223);
  --error-background: rgb(255, 230, 230);
  --accumbo-brand-color: rgb(114, 5, 58);
  --accumbo-brand-text-color: rgb(250, 233, 238);
  --modal-footer-background-color: rgb(26, 35, 43);

  --editable-text-highlight-color-start: rgba(182, 199, 212, 0.7);
  --editable-text-highlight-color-end: rgba(123, 145, 161, 0);

  --login-background-color: rgb(24, 30, 36);

  --data-missing: rgb(103, 103, 103);

  --checkbox-item-color: rgb(221, 221, 221);
  --checkbox-item-background-color: rgb(48, 48, 48);
  --checkbox-item-background-hover-color: rgb(68, 68, 68);

  --button-tertiary-color: rgb(55, 99, 42);
  --button-tertiary-background: rgb(237, 248, 234);

  --sidebar-link-color: rgb(175, 175, 175);
  --sidebar-link-color-disabled: rgb(68, 68, 68);
  --sidebar-link-color-active: rgba(0, 104, 255, 0.1);

  --card-description-color: rgb(160, 169, 179);

  --status-created-background: rgb(240, 163, 32);
  --status-pending-background: (240, 163, 32);
  --status-exemption-background: rgb(51, 128, 89);
  --status-complete-background: rgb(51, 128, 89);
  --status-failed-background: rgb(218, 30, 40);
  --status-created-color: rgb(255, 246, 214);
  --status-pending-color: rgb(255, 246, 214);
  --status-exemption-color: rgb(234, 242, 238);
  --status-complete-color: rgb(234, 242, 238);
  --status-failed-color: rgb(255, 240, 241);

  --dropdown-box-shadow: 0 2px 6px rgba(219, 219, 219, 0.2), 0 0 0 2px rgba(227, 227, 227, 0.1);

  --card-tab-link-color: rgb(163, 174, 185);
}

:root,
[data-theme='default'] {
  --navigation-background: rgb(24, 35, 55);
  --logo-image-url: url('../assets/admin.svg');
  --logo-width: 90px;
  --highlight: rgb(47, 68, 107);
  --logo-wrapper-border-color: rgb(16, 24, 38);
}

[data-theme='tech'] {
  --navigation-background: rgb(32, 31, 30);
  --logo-image-url: url('../assets/tech.svg');
  --logo-width: 73px;
  --highlight: rgb(84, 82, 79);
  --logo-wrapper-border-color: rgb(24, 23, 22);
}

[data-theme='business'] {
  --navigation-background: rgb(36, 20, 51);
  --logo-image-url: url('../assets/business.svg');
  --logo-width: 109px;
  --highlight: rgb(72, 40, 102);
  --logo-wrapper-border-color: hsl(273, 46%, 10%);
}

[data-theme='education'] {
  --navigation-background: rgb(20, 51, 40);
  --logo-image-url: url('../assets/education.svg');
  --logo-width: 122px;
  --highlight: rgb(40, 102, 80);
  --logo-wrapper-border-color: hsl(160, 46%, 10%);
}

[data-theme='superadmin'] {
  --navigation-background: rgb(2, 101, 175);
  --logo-image-url: url('../assets/super-admin.svg');
  --logo-width: 143px;
  --highlight: rgb(255, 224, 28);
  --logo-wrapper-border-color: rgb(1, 75, 131);
}
