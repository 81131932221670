.button {
  cursor: pointer;
  font-family: var(--main-font);
  font-size: 15px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 6px;
  padding: 9px 15px 12px 15px;
  margin-bottom: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;

  &:last-child {
    margin-bottom: 0;
  }

  &:focus {
    outline: none;
  }

  &:active {
    box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.1);
  }

  &[disabled] {
    &:active {
      box-shadow: none;
    }
  }

  &.full-width {
    width: 100% !important;
  }

  &.icon-button {
    padding: 4px 8px;
  }

  &.button-text {
    color: var(--primary);
    background-color: unset;

    &:hover {
      color: rgb(49, 91, 176);
      background-color: unset;
    }

    &:focus {
      box-shadow: none;
    }

    &:active {
      color: rgb(151, 173, 217);
      background-color: unset;
    }
  }

  &.button-primary {
    background-color: var(--primary);

    &:hover {
      background-color: rgb(54, 108, 217);
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px var(--body-background), 0px 0px 0px 4px rgba(var(--primary-raw-rgb), 0.25);
    }

    &:active {
      background-color: rgb(41, 83, 166);
    }

    &[disabled] {
      cursor: unset;
      background-color: var(--button-disabled-background-color);

      &:hover {
        background-color: var(--button-disabled-background-color);
      }
    }
  }

  &.button-secondary {
    color: var(--primary);
    background-color: var(--secondary);

    &:hover {
      background-color: rgb(208, 222, 247);
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px var(--body-background), 0px 0px 0px 4px rgba(var(--primary-raw-rgb), 0.25);
    }

    &:active {
      background-color: rgb(179, 202, 242);
    }

    &[disabled] {
      cursor: unset;
      color: rgb(140, 140, 140);
      background-color: rgb(230, 230, 230);

      &:hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }

  &.button-tertiary {
    color: var(--button-tertiary-color);
    background-color: var(--button-tertiary-background);

    &:hover {
      background-color: rgb(209, 228, 204);
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px var(--body-background), 0px 0px 0px 4px rgba(55, 99, 42, 0.25);
    }

    &:active {
      background-color: rgb(168, 197, 161);
    }

    &[disabled] {
      cursor: unset;
      color: rgb(140, 140, 140);
      background-color: rgb(230, 230, 230);

      &:hover {
        background-color: rgb(230, 230, 230);
      }
    }
  }

  &.button-destructive {
    background-color: rgb(218, 30, 40);

    &:hover {
      background-color: rgba(218, 30, 40, 0.89);
    }

    &:active {
      background-color: rgb(218, 30, 40);
    }
  }

  &.button-input-attached {
    font-size: 13px;
    font-weight: bold;
    padding: 2px 10px 6px 10px;
    border-radius: 0 0 4px 4px;
    background-color: var(--primary);
    min-height: 27px;

    &:hover {
      background-color: rgb(54, 108, 217);
    }

    &:active {
      background-color: rgb(41, 83, 166);
    }

    &[disabled] {
      cursor: unset;
      background-color: var(--button-disabled-background-color);

      &:hover {
        background-color: var(--button-disabled-background-color);
      }
    }

    .loader-button-image {
      bottom: -3px;
    }
  }

  &.button-size-medium {
    font-size: 13px;
    font-weight: bold;
    padding: 2px 10px 6px 10px;
    border-radius: 4px;
    min-height: 25px;
    margin-bottom: 0;
  }

  &.button-size-small {
    padding: 3px 9px 5px;
    border-radius: 3px;
    min-height: 25px;
    background-color: #efefef;
    font-size: 13px;
    font-weight: bold;
    color: #494949;

    &:hover {
      background-color: #dbdbdb;
    }
  }
}
