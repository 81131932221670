@import './colors.scss';
@import './breakpoints.scss';
@import './helpers.scss';
@import './typography.scss';
@import './icons.scss';
@import './overrides.scss';

@font-face {
  font-family: 'IsidoraSans-Bold';
  src: url('../assets/fonts/34C90D_7_0.eot');
  src: url('../assets/fonts/34C90D_7_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/34C90D_7_0.woff2') format('woff2'), url('../assets/fonts/34C90D_7_0.woff') format('woff'),
    url('../assets/fonts/34C90D_7_0.ttf') format('truetype');
}
@font-face {
  font-family: 'IsidoraSans-Regular';
  src: url('../assets/fonts/34C90D_12_0.eot');
  src: url('../assets/fonts/34C90D_12_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/34C90D_12_0.woff2') format('woff2'), url('../assets/fonts/34C90D_12_0.woff') format('woff'),
    url('../assets/fonts/34C90D_12_0.ttf') format('truetype');
}
@font-face {
  font-family: 'IsidoraSans-SemiBold';
  src: url('../assets/fonts/34C90D_16_0.eot');
  src: url('../assets/fonts/34C90D_16_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/34C90D_16_0.woff2') format('woff2'), url('../assets/fonts/34C90D_16_0.woff') format('woff'),
    url('../assets/fonts/34C90D_16_0.ttf') format('truetype');
}

@import 'bulma/sass/utilities/_all.sass';
@import 'bulma/sass/grid/columns.sass';
@import 'bulma/sass/components/dropdown.sass';
@import 'bulma/sass/components/modal.sass';
@import 'bulma/sass/elements/table.sass';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  background: var(--body-background);
  color: var(--primary-text);
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--main-font);
}

::placeholder {
  font-family: var(--main-font);
  color: rgb(128, 128, 128);
  font-size: 15px;
  font-style: italic;
}

h3 {
  font-size: 15px;
  font-weight: 600;
  color: var(--primary-text);
}

h4 {
  font-size: 14px;
  font-weight: 400;
  color: var(--hint-text);
}

a {
  color: var(--primary);
  text-decoration: none;

  &:hover {
    color: var(--primary-text);
  }

  &.external {
    background-image: url('../assets/icons/external-link-icon.svg');
    background-position-y: 65%;
    background-position-x: 100%;
    background-repeat: no-repeat;

    &[disabled] {
      background-image: none;
    }
  }
}

hr {
  margin: 20px 0;
  height: 1px;
  background-color: var(--hr-color);
  border: none;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}

.stack > * + * {
  margin-top: 1em;
}

.page-content {
  margin-top: 10px;
  background-color: var(--body-background);

  @include for-laptop-up {
    padding: 2em;
  }
}

.page-card {
  width: 100%;
  max-width: 950px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--card-background-color);

  @include for-laptop-up {
    input[type='text'],
    input[type='tel'],
    input[type='email'],
    input[type='date'],
    input[type='number'] {
      max-width: 250px;
    }
  }
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='date'],
input[type='number'],
input[type='password'],
textarea,
.fake-input {
  width: 100%;
  font-family: var(--main-font);
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid var(--input-border-color);
  background-color: var(--input-background-color);
  color: var(--input-text);
  padding: 7px 12px 11px 12px;
  min-height: 42px;

  &:focus {
    outline: none;
    border: 1px solid var(--primary);
    box-shadow: 0 0 0 2px rgba(var(--primary-raw-rgb), 0.2);
  }

  &.error {
    border-color: var(--error-primary);
  }
}

input[type='checkbox'] + label {
  user-select: none;
}

select {
  font-family: var(--main-font);
  font-size: 16px;
  border-radius: 4px;
  border: solid 1px var(--input-border-color);
  background-color: white;
  color: var(--primary-text);
  padding: 11px 20px;
  min-height: 44px;
  min-width: 218px;

  &:focus {
    outline: none;
    border: 1px solid var(--primary);
    box-shadow: 0px 0px 0px 2px rgba(var(--primary-raw-rgb), 0.2);
  }

  &[disabled] {
    background-color: rgba(167, 167, 167, 0.2);
  }

  @include for-laptop-up {
    min-width: 250px;
  }
}

.fake-input {
  display: block;
  width: 100%;
  cursor: pointer;
}

.invalid-input {
  input,
  .fake-input {
    border-color: var(--error-primary);
  }
}

.fake-input__placeholder {
  font-style: italic;
  color: rgb(128, 128, 128);
}

.column.is-2,
.column.is-4 {
  .button {
    display: block;
    width: 100%;

    @include for-laptop-up {
      display: inline;
      width: auto;
    }
  }
}

.member-avatar-fallback {
  font-family: 'IsidoraSans-Bold', sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 1em;
  text-align: center;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 0.5px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &.large {
    width: 60px;
    height: 60px;
    font-size: 20px;

    &.long-initial {
      font-size: 14px;
    }

    &.user {
      &:after {
        width: 30px;
        height: 30px;
      }
    }
  }

  &.x-large {
    width: 100px;
    height: 100px;
    font-size: 32px;
    margin-right: 0;

    &.long-initial {
      font-size: 15px;
    }

    &.user {
      &:after {
        width: 30px;
        height: 30px;
        top: 0px;
        right: 0px;
      }
    }
  }

  &.long-initial {
    font-size: 8px;
  }

  &.male {
    color: rgb(1, 87, 155);
    background-color: rgb(202, 236, 252);
  }

  &.female {
    color: rgb(74, 20, 140);
    background-color: rgb(227, 201, 231);
  }

  &.user {
    background-color: var(--accumbo-brand-color);
    color: var(--accumbo-brand-text-color);

    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: -5px;
      right: -5px;
      width: 20px;
      height: 20px;
      background-image: url('../assets/icons/employee-badge.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &.has-profile-image {
    // Removes artifacts from the background color bleeding through
    background-color: unset !important;
  }
}

.edit-link {
  cursor: pointer;
  font-size: 14px;
  font-weight: 800;
  color: var(--primary);
}

.text-button {
  cursor: pointer;
  font-size: 15px;
  font-weight: 800;
  color: var(--primary);
  user-select: none;

  &:hover {
    color: rgb(49, 91, 176);
  }

  &:active {
    color: rgb(151, 173, 217);
  }
}

.error-message {
  font-size: 14px;
  color: var(--error-primary);
}

.error-message-block {
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  color: var(--error-primary);
  background-color: var(--error-background);
  padding: 10px 12px 12px 12px;
  display: inline-block;
}

.dropdown.is-active {
  .dropdown-item {
    display: block;
  }
}

.dropdown-menu {
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(48, 12, 12, 0.2);
  background-color: var(--card-background-color);
  padding: 0;
}

.dropdown-content {
  padding: 5px;
  font-size: 15px;
  font-weight: 600;
  background-color: var(--card-background-color);
  box-shadow: var(--dropdown-box-shadow);

  a,
  > div {
    color: var(--primary-text);
    text-decoration: none;
    padding: 8px;
  }
}

.dropdown-item {
  display: none;
  border-radius: 3px;
  cursor: pointer;

  a[disabled],
  &.disabled {
    cursor: unset;
    pointer-events: none;
    color: var(--sidebar-link-color-disabled);
    background-color: var(--table-row-color);
    user-select: none;
  }

  a[disabled] {
    background-color: unset;
  }
}

div,
a,
button {
  &.dropdown-item:hover {
    color: var(--primary-text);
    background-color: var(--dropdown-item-hover-background-color);
  }
}

div,
a,
button {
  &.dropdown-item:active {
    color: var(--primary-text);
    background-color: var(--dropdown-item-active-background-color);
  }
}

.single-select__indicator-separator,
.multi-select__indicator-separator {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.single-select__indicator-separator {
  background-color: transparent !important;
}

.single-select__control,
.multi-select__control {
  font-family: var(--main-font);
  font-size: 15px;
  border-radius: 4px;
  border: 1px solid var(--input-border-color) !important;
  background-color: var(--input-background-color) !important;
  color: var(--primary-text);
  min-height: 44px;

  &:hover {
    border-color: rgb(204, 204, 204) !important;
  }

  @include for-laptop-up {
    width: 250px;
  }
}

.single-select__control--is-focused,
.multi-select__control--is-focused {
  border-color: rgb(204, 204, 204) !important;
  outline: none !important;
  box-shadow: none !important;
}

.single-select__menu,
.multi-select__menu {
  background-color: var(--input-background-color) !important;
}

.single-select__menu-list,
.multi-select__menu-list {
  padding: 5px !important;
}

.single-select__menu-notice,
.multi-select__menu-notice {
  font-size: 15px !important;
}

.single-select__option,
.multi-select__option {
  font-size: 15px !important;
  font-weight: 600;
  color: var(--input-text) !important;

  :active {
    background-color: rgba(62, 105, 184, 0.15) !important;
  }
}

.multi-select__option.multi-select__option--is-focused {
  border-radius: 4px;
  background-color: rgba(0, 104, 255, 0.1);
  color: var(--primary) !important;
}

.multi-select__multi-value {
  border-radius: 4px !important;
  background-color: rgba(0, 104, 255, 0.1) !important;
  color: var(--primary) !important;
}

.multi-select__multi-value__label {
  color: var(--primary) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.multi-select__multi-value__remove {
  &:hover {
    color: var(--error-primary) !important;
    background-color: var(--error-background) !important;
  }
}

.single-select__menu {
  max-width: 300px;
}

.single-select__option--is-focused,
.single-select__option--is-selected {
  border-radius: 4px;
  color: var(--input-text) !important;

  &:active {
    background-color: rgba(62, 105, 184, 0.15) !important;
  }
}

.single-select__option--is-selected {
  background-color: rgba(0, 104, 255, 0.1) !important;
  color: var(--primary) !important;
}

.single-select__option--is-focused {
  background-color: #f2f2f2 !important;
}

.option-expand {
  display: inline-block;
  user-select: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  color: var(--primary);

  &:after {
    content: '';
    display: inline-block;
    transition: transform 0.1s ease-in;
    width: 20px;
    height: 10px;
    background-image: url('../assets/icons/chevron_2.svg');
    background-repeat: no-repeat;
    background-position: center 2px;
  }

  &.expanded {
    &:after {
      transform: rotate(180deg);
    }
  }
}

.update-actions {
  margin-top: 20px;

  .column {
    text-align: center;

    @include for-laptop-up {
      text-align: left;
    }

    &:last-child {
      @include for-laptop-up {
        text-align: right;
      }
    }
  }
}

.card-container {
  padding: 25px;
  background-color: var(--card-background-color);
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 15px;

  h2 {
    margin-top: 0;
    margin-bottom: 5px;
  }

  h4 {
    font-family: var(--main-font);
    font-weight: 500;
  }
}

.data-missing {
  color: var(--data-missing);
  font-size: 16px;
}

.notification {
  font-family: Sarabun, sans-serif !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 0 10px 0 8px !important;
  box-shadow: 0 2px 5px 0 rgba(66, 71, 74, 0.2), 0 6px 24px 0 rgba(66, 71, 94, 0.1),
    0 50px 44px 0 rgba(66, 71, 94, 0.04) !important;
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px !important;
}

.notification-error {
  color: #242423 !important;

  .line {
    height: 44px;
    width: 5px;
    border-radius: 5px;
    background-color: var(--error-primary);
  }
}

.notification-success {
  color: #242423 !important;

  .line {
    height: 44px;
    width: 5px;
    border-radius: 5px;
    background-color: rgb(31, 166, 122);
  }
}

.notification-icon {
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: rgb(242, 242, 242);
  background-image: url('../assets/icons/modal-close-default-icon.svg');

  &:hover {
    background-color: rgb(153, 153, 153);
    background-image: url('../assets/icons/modal-close-hover-icon.svg');
  }

  &:active {
    background-color: rgb(77, 77, 77);
    background-image: url('../assets/icons/modal-close-hover-icon.svg');
  }
}

.date-search-container {
  width: 90%;
  margin: 0 auto;

  @include for-laptop-up {
    max-width: 400px;
  }

  input {
    width: 100%;
  }
}

.text-container {
  overflow-y: auto;
  margin: 0 auto;
  padding: 25px 30px;
  max-width: 1096px;
  max-height: 45vh;

  @include for-laptop-up {
    height: 60vh;
  }
}

.copied-highlight {
  animation: copied-highlight 2.75s cubic-bezier(0.25, 0.46, 0.45, 0.94) forwards;
}

@keyframes copied-highlight {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

input[type='date']:not(.has-value):before {
  font-style: italic;
  color: rgb(128, 128, 128);
  content: attr(placeholder);
}

.native-date-picker {
  @include for-laptop-up {
    display: none;
  }
}

.non-native-date-picker {
  display: none;
  @include for-laptop-up {
    display: block;
  }
}

.jira-service-desk-logo {
  width: 164px;
  height: 18px;
  background-image: url('../assets/jira-service-desk.svg');
  background-repeat: no-repeat;
  margin-bottom: 20px;
}

.jira__no-content {
  font-size: 16px;
  font-weight: 500;
  color: #676767;
}

.details-container {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;

  .columns:not(:last-child) {
    margin-bottom: 0;
  }
}

.edit-profile-container {
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
}

.editable-value__control {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  right: 10px;
  z-index: 0;
  top: 0;
  transition: top 0.08s ease;

  &.active {
    opacity: 1;
    top: 100%;
  }

  .loading-icon {
    margin-top: 2px;
  }
}

.system-user-title {
  font-weight: normal;
  font-size: 16px;
  color: #676767;
}

.checkbox-list {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.checkbox-row {
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: var(--checkbox-item-color);
  background-color: var(--checkbox-item-background-color);
  border-radius: 4px;
  margin-bottom: 1em !important;
  padding: 10px 5px;
  padding-right: 10px;
  min-height: 45px;

  .column {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .cross {
    z-index: 10;
    display: none;
  }

  .button {
    display: none;

    &.button-primary,
    &.button-secondary {
      &:focus {
        box-shadow: none;
      }
    }
  }

  &:hover {
    background-color: var(--checkbox-item-background-hover-color);

    .cross {
      display: inline-block;
    }

    .button {
      display: inline-block;
    }
  }

  .is-1 {
    width: 30px;
  }

  &.disabled {
    cursor: default;
    color: #a9a9a9;

    &:hover {
      background-color: var(--checkbox-item-background-color);
    }
  }

  .button {
    width: auto !important;
  }
}

.table {
  border-collapse: collapse;
  color: var(--table-cell-color);

  th {
    color: var(--table-cell-color);
  }

  tfoot td,
  tfoot th,
  thead td,
  thead th {
    color: var(--primary-text);
  }

  tr {
    background-color: var(--table-row-color-hover);
  }

  td,
  th {
    border-color: var(--table-border-color);
  }
}

.table.is-striped tbody tr:not(.is-selected):nth-child(even) {
  background-color: var(--table-row-color);
}

.info-circle {
  font-weight: bold;
  background-color: #bbbbbb;
  color: #ffffff;
  width: 25px;
  height: 25px;
  display: inline-block;
  padding-left: 8px;
  padding-top: 1px;
  border-radius: 15px;
  cursor: default;
}

[type='file'] {
  height: 0;
  width: 0;
  overflow: hidden;

  & + label {
    cursor: pointer;
    font-family: var(--main-font);
    font-size: 15px;
    font-weight: 600;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 6px;
    padding: 9px 15px 12px 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;

    color: var(--primary);
    background-color: var(--secondary);

    &:hover {
      background-color: rgb(208, 222, 247);
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px var(--body-background), 0px 0px 0px 4px rgba(var(--primary-raw-rgb), 0.25);
    }

    &:active {
      background-color: rgb(179, 202, 242);
    }
  }
}
