.content-loader {
  width: 30px;
  height: 30px;
  margin: auto;
  top: -20px;
  bottom: 0;
  right: 0;
  left: 0;
  margin-bottom: 30px;

  &:last-child {
    margin-bottom: 0;
  }
}
