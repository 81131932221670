.dropdown-menu-actions {
  text-align: left;

  .icon {
    padding: 20px;
  }

  .icon:hover {
    border-radius: 50%;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  }
}
