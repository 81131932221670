@import '../../styles/breakpoints.scss';

.login-wrapper {
  height: 100vh;
  background-color: var(--login-background-color);
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: calc(100vh - 9em);

  @include for-laptop-up {
    height: calc(100vh - 80px);
  }

  ::placeholder {
    color: #808080;
    font-size: 16px;
    font-style: italic;
  }

  h1 {
    font-family: IsidoraSans-SemiBold, sans-serif;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
  }

  .login-bankid-logo {
    display: inline-block;
    width: 110px;
    height: 110px;
    background-image: url('../../assets/bank-id-app-logo.svg');
    background-repeat: no-repeat;
    margin-bottom: 10px;
    object-fit: contain;
    margin-top: 20px;

    @include for-laptop-up {
      margin-top: 0;
    }
  }

  .login-form {
    .columns {
      display: flex;
    }

    .column.is-8 {
      flex: none;
      width: 66.66667%;
    }

    .column.is-4 {
      flex: none;
      width: 33.33333%;
    }

    @include for-laptop-up {
      margin-top: 150px;
    }

    text-align: center;
    width: 350px;

    form {
      padding-top: 120px;
      background-image: url('../../assets/accumbo-admin-logo.svg');
      background-repeat: no-repeat;
      background-position: center 40px;
    }

    .login-error {
      position: relative;
      width: calc(100% + 4px);
      left: -2px;
      text-align: left;
      color: var(--error-primary);
      background-color: var(--error-background);
      font-size: 14px;
      padding: 6px 10px;
      border-radius: 0 0 4px 4px;
    }

    input.error {
      border: 1px solid var(--error-primary);
      box-shadow: 0px 4px 0px 2px var(--error-background), 0px 0px 0px 2px var(--error-background);
    }

    input[type='text'] {
      max-width: unset;
    }
  }

  input[type='text'] {
    font-family: var(--main-font);
    font-size: 18px;
    padding: 0 20px;
    height: 50px;
    width: 100%;
    border-radius: 4px;
    border: solid 1px var(--input-border-color);
    background-image: url('../../assets/bank-id-small.svg');
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;

    &:focus {
      outline: none;
      border: 1px solid var(--primary);
      box-shadow: 0px 0px 0px 2px rgba(var(--primary-raw-rgb), 0.25);
    }

    &:hover {
      border-color: rgb(153, 153, 153);
    }
  }

  button[type='submit'] {
    font-family: var(--main-font);
    display: block;
    margin-top: 10px;
    width: 100%;
    height: 46px;
    border-radius: 4px;
    background-color: var(--primary);
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    border: none;
  }
}

.login-footer {
  width: 100%;
  text-align: center;
  font-family: IsidoraSans-Regular, sans-serif;
  font-size: 12px;
  color: #bfbfbf;

  strong {
    font-family: IsidoraSans-SemiBold, sans-serif;
  }

  .footer-logo {
    margin: 0 auto;
    width: 200px;
    height: 24px;
    background-image: url('../../assets/blodtrycksdoktorn-light-logo.svg');
    margin-bottom: 15px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
  }
}

.loading-dots {
  margin-top: 55px;
  text-align: left;
  padding-left: 25%;
}

.build-number {
  font-size: 8px;
  position: absolute;
  bottom: 5px;
  right: 5px;

  @include for-laptop-up {
    font-size: 12px;
  }
}
