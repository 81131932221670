.bulk-message {
  .multi-select__control {
    width: 100% !important;
  }

  .button {
    width: auto;
  }

  textarea {
    width: 100%;
    min-height: 240px;
  }

  h4 {
    color: #6f829f;
    font-weight: 500;
  }
}
