@import '../../../styles/breakpoints.scss';

.search-container {
  width: 90%;
  margin: 0 auto;
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--card-background-color);
  padding: 15px;

  @include for-tablet-portrait-up {
    max-width: 548px;
  }
}
