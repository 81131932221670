@import '../../styles/breakpoints.scss';

.card {
  position: relative;
  margin-bottom: 30px;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--card-background-color);
  padding: 25px;

  @include for-laptop-up {
    max-width: 550px;

    &.large {
      max-width: 850px;
    }

    &.x-large {
      max-width: 1150px;
    }

    .fake-input {
      max-width: none !important;
    }
  }

  .single-select__control {
    width: 100%;
  }

  h4 {
    margin-top: 0;
  }

  .button {
    width: 100%;
  }

  .card-header .button {
    width: auto;
  }

  .card-actions {
    margin-left: 10px;

    button {
      margin-right: 10px;

      &:last-of-type {
        position: absolute;
        right: 0;
      }
    }
  }
}

.card-description {
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.43;
  color: var(--card-description-color);
}

.card-header {
  font-family: 'IsidoraSans-Bold', sans-serif;
  font-size: 18px;
  margin-bottom: 25px;
}

.card-tab-links {
  font-size: 14px;
  a {
    margin-right: 15px;
    color: var(--card-tab-link-color);

    &.is-active {
      color: var(--primary);
    }
  }
}
