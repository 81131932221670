.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  background-color: var(--primary) !important;
}

.rst__lineHalfHorizontalRight::before {
  height: 3px !important;
}

.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  width: 3px !important;
}

.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  width: 3px !important;
}

.rst__lineChildren::after {
  background-color: var(--primary) !important;
  width: 3px !important;
}

.rst__rowContents {
  background-color: var(--card-background-color) !important;
  border-radius: 0 5px 5px 0 !important;
  border-color: var(--table-border-color);
}

.rst__moveHandle {
  background-color: #3060be !important;
  border: none !important;
  border-radius: 5px 0 0 5px !important;
}

.rst__rowToolbar {
  margin-right: 10px;
}

.rst__toolbarButton {
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.canned-responses {
  .new-main-category {
    display: inline-block;
    border: 2px dashed #00308f;
    border-radius: 8px;
    padding: 8px 37px 11px 15px;
    font-size: 20px;
    font-weight: 600;
    color: #00308f;
  }

  .delete-icon {
    cursor: pointer;
    display: inline-block;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: rgb(242, 242, 242);
    background-image: url('../../assets/icons/modal-close-default-icon.svg');

    &:hover {
      background-color: var(--error-primary);
      background-image: url('../../assets/icons/modal-close-hover-icon.svg');
    }

    &:active {
      background-color: rgb(124, 37, 37);
      background-image: url('../../assets/icons/modal-close-hover-icon.svg');
    }
  }
}
