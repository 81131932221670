@import '../../styles/breakpoints.scss';

.doctor-statistics {
  position: relative;

  .picker {
    display: inline-block;
    min-width: 200px;
  }

  td {
    height: 44px;
  }

  td .button {
    width: auto;
    margin-left: 10px;

    @include for-laptop-up {
      display: none;
    }
  }

  @include for-laptop-up {
    tr:hover .button {
      display: inline-flex;
    }
  }

  thead th,
  tbody th {
    white-space: nowrap;
  }
}

.billing-period-header {
  width: 175px;
  text-align: right !important;
}

.pdf-download-button .button {
  position: relative;
  right: 4px;
  bottom: 4px;
}
