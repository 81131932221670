.system-user-details {
  .editable-value__control {
    transition: none;
  }
}

.authority-container {
  input[type='checkbox'] + label {
    font-weight: bold;
    margin-left: 5px;
  }
}

.authority-description {
  margin-left: 25px;
  font-size: 14px;
  font-weight: normal;
  opacity: 0.9;
}

.authority-columns {
  display: flex;

  > div {
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }

  .is-1 {
    flex: none;
    width: 10%;
    min-width: 41px;
  }
}
