@import '../../../styles/breakpoints.scss';

$date-row-width: 10%;
$icon-row-width: 14%;

.search-results-table__empty {
  display: flex;
  height: 180px;
  margin: 0 auto;
  margin-top: 30px;
  width: 258px;
  text-align: center;
  background-image: url('../../../assets/no-search-results.svg');
  background-position: center top;
  background-repeat: no-repeat;
  align-items: flex-end;
  justify-content: center;
  font-size: 15px;
  line-height: 1.47;
  color: rgb(103, 103, 103);

  @include for-laptop-up {
    margin-top: 50px;
  }
}

.search-results-table-loader {
  position: absolute;
  width: 100px;
  height: 100px;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.r-table-row {
  cursor: pointer;
  display: block;
  border-bottom: 1px solid var(--table-border-color);
  background-color: transparent;

  @include for-laptop-up {
    background-color: var(--table-row-color);
    padding: 2px 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &:hover {
    background-color: var(--table-row-color-hover);
  }
}

.r-table-headers {
  display: none;
  margin: 0;
  padding: 0;
  background-color: var(--table-header-color);
  border-bottom: 1px solid var(--table-border-color);

  @include for-laptop-up {
    display: flex;
    flex-wrap: wrap;
  }
}

.r-table-header {
  flex-grow: 1;
  padding: 10px 20px;
  overflow: hidden;
  color: var(--hint-text);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 800;
  user-select: none;

  .column__sorted {
    color: var(--primary);

    + span {
      position: relative;
      display: none;
      margin-left: 4px;
      top: -1px;
      background-size: contain;
      width: 8px;
      height: 5px;
    }

    + .column__sorted-desc {
      display: inline-block;
      background-image: url('../../../assets/icons/small-chevron.png');
    }

    + .column__sorted-asc {
      display: inline-block;
      background-image: url('../../../assets/icons/small-chevron.png');
      transform: rotate(180deg);
    }
  }

  @include for-laptop-up {
    width: 100%;
  }
}

.r-table-cell {
  flex-grow: 1;
  padding: 10px 20px;
  overflow: hidden;
  background-color: var(--table-header-color);
  color: var(--table-cell-color);
  border-bottom: 1px solid var(--table-border-color);

  strong {
    font-family: 'IsidoraSans-SemiBold', sans-serif;
  }

  @include for-laptop-up {
    width: 100%;
    background-color: unset;
    border-bottom: none;
  }
}

.r-table-cell__foot {
  margin-bottom: 1em;

  @include for-laptop-up {
    margin-bottom: 0;
  }
}

.member-table {
  .r-table-cell__foot {
    margin-bottom: 1em;

    @include for-laptop-up {
      margin-bottom: 0;
      width: $icon-row-width !important;
    }
  }

  .r-table-header {
    &:nth-last-child(2) {
      width: $icon-row-width !important;
    }

    @include for-laptop-up {
      width: 100%;

      &:last-child {
        width: $date-row-width !important;
        pointer-events: none;
      }
    }
  }

  .r-table-cell {
    @include for-laptop-up {
      &:nth-last-child(2) {
        width: $date-row-width !important;
      }
    }
  }
}

.mobile-column-header {
  @include for-laptop-up {
    display: none;
  }
}

.r-table__2-cols .r-table-cell {
  width: 100%;
  @include for-laptop-up {
    width: 50%;
  }
}
.r-table__3-cols .r-table-cell {
  width: 100%;
  @include for-laptop-up {
    width: 33.3333%;
  }
}
.r-table__4-cols .r-table-cell {
  width: 100%;
  @include for-laptop-up {
    width: 25%;
  }
}
.r-table__5-cols .r-table-cell {
  width: 100%;
  @include for-laptop-up {
    width: 20%;
  }
}
.r-table__6-cols .r-table-cell {
  width: 100%;
  @include for-laptop-up {
    width: 16.6667%;
  }
}

.r-table__2-cols > .r-table-header {
  width: 100%;
  @include for-laptop-up {
    width: 50%;
  }
}
.r-table__3-cols > .r-table-header {
  width: 100%;
  @include for-laptop-up {
    width: 33.3333%;
  }
}
.r-table__4-cols > .r-table-header {
  width: 100%;
  @include for-laptop-up {
    width: 25%;
  }
}

.r-table-cell__head {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
