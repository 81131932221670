.editable-value {
  position: relative;
  display: flex;
}

.editable-value__current-value {
  z-index: 1;
  width: 100%;
  &.has-unit {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-right: 0;
    }
  }

  &.invalid-value {
    input:focus {
      border-color: var(--error-primary);
      box-shadow: 0 0 0 2px rgba(var(--error-primary-raw-rgb), 0.2);
    }
  }
}

.editable-value__unit {
  z-index: 1;
  border-radius: 0 4px 4px 0;
  border: solid 1px rgb(224, 224, 224);
  background-color: rgb(250, 250, 250);
  color: rgb(89, 89, 89);
  padding: 10px;
  font-size: 13px;
}
