.payment-history {
  .partially-hidden {
    &:before {
      position: absolute;
      content: '';
      width: calc(100% + 1.5rem);
      height: 40px;
      background-image: linear-gradient(transparent 0%, var(--card-background-color) 75%);
    }

    .button .icon {
      position: static;
    }
  }

  .button .icon {
    position: relative;
    top: 2px;
  }
}

.payment-state {
  padding: 0.3em 0.6em;
  font-weight: bold;
  border-radius: 4px;
  cursor: default;

  &__created {
    background-color: var(--status-created-background);
    color: var(--status-created-color);
  }

  &__pending {
    background-color: var(--status-pending-background);
    color: var(--status-pending-color);
  }

  &__exemption {
    background-color: var(--status-exemption-background);
    color: var(--status-exemption-color);
  }

  &__complete {
    background-color: var(--status-complete-background);
    color: var(--status-complete-color);
  }

  &__failed,
  &__error,
  &__cancelled {
    background-color: var(--status-failed-background);
    color: var(--status-failed-color);
  }
}
