@import '../../styles/breakpoints.scss';

.error-fallback-wrapper {
  display: flex;
  height: 220px;
  margin: 0 auto;
  margin-top: 30px;
  width: 300px;
  text-align: center;
  background-image: url('../../assets/profile-failed.svg');
  background-position: center top;
  background-repeat: no-repeat;
  align-items: flex-end;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  color: rgb(103, 103, 103);

  @include for-laptop-up {
    margin-top: 200px;
  }

  .error-content {
    display: block;
  }
}
