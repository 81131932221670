@import '../../styles/breakpoints.scss';

$navigation-height: 60px;
$drawer-timing: 0.2s;
$drawer-width: 310px;
$drawer-easing-function: cubic-bezier(0.4, 0, 0.2, 1);
$nav-option-opacity: 0.8;

.navigation-bar {
  height: $navigation-height;
  background-color: var(--navigation-background);
  color: #ffffff;

  // Future? Fixed mobile menu
  // position: fixed;
  // width: 100%;
  // z-index: 9999;
  // top: 0;

  // @include for-laptop-up {
  //   position: static;
  //   top: auto;
  // }

  .logo-wrapper {
    border-right: 2px solid var(--logo-wrapper-border-color);
  }

  .desktop {
    display: none;

    @include for-laptop-up {
      display: flex;
    }
  }

  .column {
    padding: 0 !important;
    margin: 0 !important;

    display: flex;
    align-items: center;
  }

  .columns {
    margin: 0 !important;
    height: 100%;
  }

  .navigation-tabs {
    font-family: 'IsidoraSans-SemiBold', sans-serif;
    display: none;
    justify-content: center;

    @include for-laptop-up {
      display: flex;
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-block;

      border-bottom: none;
      height: 100%;
    }

    li {
      display: inline-block;
      padding: 0 1em;
      list-style-type: none;
      height: 100%;
      position: relative;

      &:first-child {
        padding-left: 0;
      }
    }

    a {
      position: relative;
      display: inline-block;
      height: 100%;
      text-decoration: none;
      font-size: 16px;
      color: rgb(255, 255, 255);
      opacity: $nav-option-opacity;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 2px;
      padding: 0 6px;

      &:hover {
        opacity: 1;
      }

      &.is-active {
        opacity: 1;
        border-bottom: 3px solid var(--highlight);
      }
    }
  }

  .mobile {
    display: flex;
    align-items: center;
    height: 100%;

    @include for-laptop-up {
      display: none;
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-block;
    }

    li {
      padding: 0;
      margin-left: 20px;
      margin-bottom: 0;
      list-style-type: none;
      font-family: 'IsidoraSans-SemiBold', sans-serif;
      font-size: 16px;
      letter-spacing: 0.2px;

      > a {
        display: none;
        text-decoration: none;
        color: rgb(255, 255, 255);
      }

      .current-page {
        display: inline;
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: transparent;
      }
    }
  }
}

.section-logo {
  position: relative;
  top: 15px;
  margin-left: 15px;
  height: 26px;
  width: var(--logo-width);
  background-image: var(--logo-image-url);

  @include for-laptop-up {
    top: 0;
  }
}

.user-image {
  display: inline-block;
  width: 35px;
  height: 35px;
  font-size: 16px;
  font-weight: bold;
  margin-right: 10px;
  color: rgb(255, 255, 255);
  background-color: var(--highlight);
  border-radius: 50%;

  span,
  img {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.user-dropdown {
  display: flex;
  align-items: center;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;

  @include for-laptop-up {
    user-select: auto;
  }

  span {
    cursor: pointer;
  }

  .username {
    font-size: 16px;
    font-weight: bold;
    white-space: nowrap;
  }
}

.burger-icon {
  width: 24px;
  height: 18px;
  margin-left: 10px;

  span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.drawer-overlay {
  transition: opacity $drawer-timing $drawer-easing-function;
  opacity: 0;
  position: fixed;
  z-index: 9999;
  height: 100vh;
  width: 100%;
  top: 0;
  background-color: rgb(0, 0, 0);
  pointer-events: none;

  &.active {
    opacity: 0.8;
    pointer-events: auto;
  }

  @include for-laptop-up {
    display: none;
  }
}

.drawer-menu {
  position: fixed;
  z-index: 9999;
  left: -$drawer-width;
  width: $drawer-width;
  height: 100vh;
  top: 0;
  transition: left $drawer-timing $drawer-easing-function;
  color: rgb(255, 255, 255);
  font-family: 'IsidoraSans-SemiBold', sans-serif;
  background-color: rgb(18, 28, 46);

  &.open {
    left: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  }

  .section-logo {
    margin-left: 20px;
    top: auto;
  }

  .nav-options > div {
    height: 32px;
    margin-bottom: 18px;

    a {
      -webkit-touch-callout: none;
      -webkit-tap-highlight-color: transparent;
      display: block;
      font-family: 'IsidoraSans-SemiBold', sans-serif;
      text-decoration: none;
      padding-left: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 18px;
      letter-spacing: 0.23px;
      color: rgba(255, 255, 255, $nav-option-opacity);
      user-select: none;
    }

    .nav-option-active {
      border-left: 3px solid var(--highlight);
      color: rgb(255, 255, 255);
      padding-left: 17px;
    }
  }

  @include for-laptop-up {
    display: none;
  }
}

.current-user {
  display: flex;
  align-items: center;
  height: 60px;
  padding-left: 1em;
  background-color: rgba(0, 0, 0, 0.1);
}

.current-section {
  margin: 40px 0;
}
