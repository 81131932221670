@import './breakpoints.scss';

.mt-5 {
  margin-top: 5px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}
.mr-10 {
  margin-right: 10px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.ml-10 {
  margin-left: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}
.mr-15 {
  margin-right: 15px !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.ml-15 {
  margin-left: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.ml-20 {
  margin-left: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}
.mr-25 {
  margin-right: 25px !important;
}
.mb-25 {
  margin-bottom: 25px !important;
}
.ml-25 {
  margin-left: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.ml-30 {
  margin-left: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}
.mr-35 {
  margin-right: 35px !important;
}
.mb-35 {
  margin-bottom: 35px !important;
}
.ml-35 {
  margin-left: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.ml-40 {
  margin-left: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}
.mr-50 {
  margin-right: 50px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.ml-50 {
  margin-left: 50px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.pt-0 {
  padding-top: 0px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pl-0 {
  padding-left: 0px !important;
}

.pt-5 {
  padding-top: 5px !important;
}
.pr-5 {
  padding-right: 5px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}
.pl-5 {
  padding-left: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pl-10 {
  padding-left: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pl-15 {
  padding-left: 15px !important;
}

.pt-20 {
  padding-top: 20px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pl-20 {
  padding-left: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
.pb-30 {
  padding-bottom: 30px !important;
}
.pl-30 {
  padding-left: 30px !important;
}

.pt-35 {
  padding-top: 35px !important;
}
.pr-35 {
  padding-right: 35px !important;
}
.pb-35 {
  padding-bottom: 35px !important;
}
.pl-35 {
  padding-left: 35px !important;
}

.pt-40 {
  padding-top: 40px !important;
}
.pr-40 {
  padding-right: 40px !important;
}
.pb-40 {
  padding-bottom: 40px !important;
}
.pl-40 {
  padding-left: 40px !important;
}

.mt-0 {
  margin-top: 0px !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.ml-0 {
  margin-left: 0px !important;
}

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}

.pos-rel {
  position: relative !important;
}
.pos-abs {
  position: absolute !important;
}

.pointer {
  cursor: pointer !important;
}
.cursor-default {
  cursor: default !important;
}
.cursor-help {
  cursor: help !important;
}
.cursor-not-allowed {
  cursor: not-allowed !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.fs-12 {
  font-size: 12px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}

.opacity-0 {
  opacity: 0 !important;
}
.opacity-6 {
  opacity: 0.6 !important;
}
.opacity-7 {
  opacity: 0.7 !important;
}

.min-height-400 {
  min-height: 400px !important;
}
.min-height-210 {
  min-height: 210px !important;
}

.right-align {
  display: flex !important;
  justify-content: flex-end;
}
.vertical-align {
  display: flex !important;
  align-items: center;
}
.bottom-align {
  display: flex;
  align-items: flex-end;
}
.text-right {
  text-align: right !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.hidden {
  display: none !important;
}
.rotated {
  transform: rotate(180deg);
}
.bordered {
  border-radius: 4px;
  border: solid 1px rgb(204, 204, 204);
}
.inverted-100 {
  filter: invert(100%);
}
.inverted-50 {
  filter: invert(50%);
}
.desktop-only {
  display: none;
  @include for-laptop-up {
    display: block;
  }
}
.mobile-only {
  display: block;
  @include for-laptop-up {
    display: none;
  }
}
.space-between {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
.overflow-hidden {
  overflow: hidden;
}
.oy-auto {
  overflow-y: auto;
}
.is-marginless {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.fw-normal {
  font-weight: normal !important;
}
.block {
  display: block;
}
.inline {
  display: inline-block !important;
}
.inline-flex {
  display: inline-flex !important;
}
.fw-bold {
  font-weight: bold;
}
.no-wrap {
  white-space: nowrap;
}
.flex {
  @media screen and (min-width: 769px) {
    display: flex;
  }
}
.flex-none {
  flex: none !important;
}
.pointer-events-none {
  pointer-events: none;
}
.usn {
  user-select: none !important;
}
.w-auto {
  width: auto !important;
}
.flex-1 {
  flex: 1;
}
.justify-center {
  justify-content: center;
}
.no-wrap {
  white-space: nowrap;
}
