@import '../../styles/breakpoints.scss';

.modal {
  .columns {
    margin: 0;
  }
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  font-family: var(--main-font);
  border-radius: 5px;
  background-color: var(--card-background-color);
  box-shadow: 0 1px 6px 0 rgba(62, 23, 23, 0.25);
  pointer-events: all;

  h2 {
    margin-top: 0;
    font-weight: 400;
  }

  h3 {
    font-size: 16px;
    font-weight: 400;
    margin-top: 0;
  }

  h4 {
    font-family: var(--main-font);
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 600;
  }

  &.modal-content__large {
    @include for-laptop-up {
      width: 65%;

      .modal-content__children {
        height: 75vh;
        overflow-y: auto;

        textarea {
          height: 100%;
        }
      }
    }
  }

  &.modal-content__medium {
    @include for-laptop-up {
      width: 45%;

      .modal-content__children {
        height: 55vh;
        overflow-y: auto;

        textarea {
          height: 100%;
        }
      }
    }
  }

  &.modal-content__small {
    @include for-laptop-up {
      width: 33%;
      max-width: 500px;

      .modal-content__children {
        height: auto;
        max-height: 400px;
        overflow-y: auto;
      }
    }
  }

  &.modal-content__x-small {
    @include for-laptop-up {
      width: 25%;
      max-width: 500px;

      .modal-content__children {
        height: auto;
      }
    }
  }

  &.modal-content__auto {
    @include for-laptop-up {
      width: auto;

      .modal-content__children {
        height: auto;
      }
    }
  }
}

.modal-close-button {
  user-select: none;
  border: none;
  cursor: pointer;
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  outline: none;
  position: relative;
  height: 20px;
  width: 20px;
  margin-left: auto;
  background-image: url('../../assets/icons/cross.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.modal-content__header {
  padding: 0 20px 13px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--card-header-border-color);

  h2 {
    font-family: var(--main-font);
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }
}

.modal-content__children {
  padding: 20px;
}

.modal-content__footer {
  background-color: var(--modal-footer-background-color);
  padding: 15px 20px;

  .column {
    padding-bottom: 10px;
  }

  button {
    .column {
      padding-bottom: 0;
    }
  }
}

.modal-action {
  text-align: right;
  padding-right: 0;
}

.handle {
  // cursor: move;
  height: 20px;
}
