.loading-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;

  &.icon {
    background-image: url('../../assets/main-logo.png');
    animation: 1s infinite heartbeat;
  }

  &.spinner {
    background-image: url('../../assets/spinner-small-primary.png');
    animation: spin 1s linear infinite;
  }

  &.spinner-secondary {
    background-image: url('../../assets/spinner-small-secondary.png');
    animation: spin 1s linear infinite;
  }

  &.small {
    width: 20px;
    height: 20px;
  }

  &.x-small {
    width: 16px;
    height: 16px;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
