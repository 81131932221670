:root {
  --main-font: 'Sarabun', sans-serif;
}

h1,
h2,
h3,
h4 {
  color: var(--primary-text);
  font-family: 'IsidoraSans-SemiBold';
  margin: 10px 0;
}

h1 {
  font-size: 20px;
}

h2 {
  font-size: 18px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 14px;
}
