@import '../../styles/breakpoints.scss';

.vacation-planner {
  .secondary-pal-list {
    line-height: 1.5;
  }

  .single-select__control,
  .multi-select__control {
    @include for-laptop-up {
      width: auto;
    }
  }
}
