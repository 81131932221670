span,
div {
  &.icon {
    transition: transform 0.1s ease-in;
    display: inline-block;
    min-width: 40px;
    height: 40px;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;

    &.small {
      min-width: 25px;
      height: 25px;
    }

    &.x-small {
      min-width: 16px;
      height: 16px;
    }

    &.xx-small {
      min-width: 10px;
      height: 10px;
    }

    &.chevron {
      background-image: url('../assets/icons/chevron.svg');
    }

    &.more {
      background-image: url('../assets/icons/more.svg');
    }

    &.cross {
      background-image: url('../assets/icons/modal-close-default-icon.svg');
    }

    &.jira {
      position: relative;
      top: 4px;
      background-image: url('../assets/icons/jira-logo-gradient-white.svg');
      min-width: 20px;
      height: 20px;
      margin-right: 12px;
    }

    &.left {
      background-image: url('../assets/icons/arrow-left.png');
      background-size: contain;
    }

    &.right {
      background-image: url('../assets/icons/arrow-right.png');
      background-size: contain;
    }

    &.edit {
      background-image: url('../assets/icons/edit.svg');
      background-size: contain;
    }

    &.copy {
      background-image: url('../assets/icons/copy.png');
      background-size: 12px 12px;
      background-color: var(--primary);
      border-radius: 3px;
      min-width: 18px;
      height: 18px;

      &:hover {
        background-color: rgb(49, 91, 176);
      }

      &:active {
        background-color: rgb(151, 173, 217);
      }
    }

    &.pdf {
      background-image: url('../assets/icons/pdf.png');
      background-size: contain;
      height: 17px;
      min-width: 22px;
    }
  }

  &.search-result-icon {
    position: relative;
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    margin-right: 5px;
    padding: 3px;
    background-position-y: center;
    background-position-x: center;
    background-repeat: no-repeat;

    &.measurement {
      background-color: #d9fbe4;
      background-image: url('../assets/icons/init-bp.svg');
      background-size: 90%;
    }

    &.lab-result {
      background-color: #e1faf4;
      background-image: url('../assets/icons/card-status-labresults.svg');
    }

    &.blocked {
      background-color: #ffe8ea;
      background-image: url('../assets/icons/card-status-warning.svg');
      background-size: 75%;
      background-position: 3px 3px;
    }

    &.monitor-shipped {
      background-color: #00a0d6;
      background-image: url('../assets/icons/delivery.svg');
      background-size: 85%;
      background-position: 1px 2px;
    }

    &.support-issue-open {
      background-color: #0747a6;
      background-image: url('../assets/icons/jira-logo-gradient-white.svg');
      background-size: 70%;
      background-position: 3px 4px;
    }

    &.support-issue-closed {
      background-color: #b9b9b9;
      background-image: url('../assets/icons/jira-logo-gradient-white.svg');
      background-size: 70%;
      background-position: 3px 4px;
    }
  }
}

:root[data-theme-variant='dark'] {
  .search-result-icon {
    filter: saturate(75%);
  }
}
