@import '../../../styles/breakpoints.scss';

.pagination-control {
  @include for-laptop-up {
    display: flex;
    justify-content: center;
  }

  padding: 14px 0;

  div {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 12px;
    background-color: var(--card-background-color);
    border-right: solid 1px var(--card-header-border-color);
    border-top: solid 1px var(--card-header-border-color);
    border-bottom: solid 1px var(--card-header-border-color);
    user-select: none;
  }

  .previous-page {
    border-radius: 3px 0 0 3px;
    border-left: solid 1px var(--card-header-border-color);
  }

  .next-page {
    border-radius: 0 3px 3px 0;
  }

  .disabled {
    pointer-events: none;
    color: #808080;
  }

  .current-page {
    color: rgba(255, 255, 255, 0.87);
    background-color: var(--primary);
    border: solid 1px rgba(0, 0, 0, 0.1);
  }
}
