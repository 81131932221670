.editable-highlight {
  animation: border-highlight 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) backwards;
}

@keyframes border-highlight {
  0% {
    box-shadow: 0 0 1px 0px var(--card-background-color), 0 0 1px 0px var(--editable-text-highlight-color-start);
  }
  100% {
    box-shadow: 0 0 1px 8px var(--card-background-color), 0 0 1px 10px var(--editable-text-highlight-color-end);
  }
}
