.search-bar {
  position: relative;
  input {
    width: 100%;
    max-width: 100%;
    padding-left: 45px;
    background-image: url('../../../assets/icons/search.svg');
    background-repeat: no-repeat;
    background-position-x: 14px;
    background-position-y: 50%;
  }

  .icon {
    position: absolute;
    right: 4px;
    top: 14px;
    cursor: pointer;
  }

  .loading,
  .clear {
    position: relative;
    span {
      position: absolute;
      top: -30px;
      right: 10px;
    }
  }

  .clear span {
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-image: url('../../../assets/icons/cross.png');
    background-size: contain;
    background-color: transparent;
  }
}
