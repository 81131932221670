@import '../../styles/breakpoints.scss';

.services {
  width: 100%;
  max-width: 1080px;
  margin: 0 auto;

  > .columns {
    margin-left: 0;
    margin-right: 0;
  }

  .sub-menu-wrapper {
    margin-bottom: 25px;

    @include for-laptop-up {
      margin-right: 25px;
      margin-bottom: 0;
    }
  }

  .hint-arrow {
    display: none;
    @include for-laptop-up {
      display: block;
      width: 215px;
      height: 102px;
      background-image: url('../../assets/hint-arrow.svg');
      background-size: contain;
    }
  }

  .no-selection {
    display: none;

    @include for-laptop-up {
      display: block;
      font-size: 14px;
      font-weight: 500;
      font-style: italic;
      text-align: center;
      color: #9a9a9a;
      width: 150px;
      margin-left: 130px;
    }
  }
}

.sub-menu {
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  background-color: var(--card-background-color);
  padding: 5px;
  position: sticky;
  top: 30px;

  a {
    display: block;
    padding: 8px 10px 10px 10px;
    border-radius: 5px;
    background-color: var(--table-row-color);
    font-size: 14px;
    font-weight: 600;
    color: var(--sidebar-link-color);

    &:hover {
      background-color: var(--table-row-color-hover);
    }

    &[disabled] {
      cursor: unset;
      pointer-events: none;
      color: var(--sidebar-link-color-disabled);
      background-color: var(--table-row-color);
    }
  }

  .is-active {
    color: var(--primary);
    background-color: var(--sidebar-link-color-active);
  }
}
