@import '../../styles/breakpoints.scss';

.user-profile {
  position: relative;

  h2 {
    font-size: 20px;
    font-family: 'IsidoraSans-Bold', sans-serif;
    margin-top: 15px;
    margin-bottom: 4px;
  }

  h3 {
    font-family: 'IsidoraSans-SemiBold', sans-serif;
    margin: 0;
  }

  .source-tag {
    display: inline-block;
    border-radius: 4px;
    margin-top: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    padding: 3px 8px 5px 8px;
    text-transform: uppercase;
    background-color: var(--primary);

    &.euroaccident {
      background-color: var(--euroaccident);
    }

    &.mindoktor {
      background-color: var(--mindoktor);
    }
  }
}

.block-member-container {
  max-height: 76px;
}

.comment-container {
  padding-top: 10px;
  transition: background-color 150ms ease-in;

  .comment-header {
    &.has-color {
      color: rgba(36, 36, 36, 0.8);
    }
  }

  textarea {
    height: 130px;
  }

  .color {
    display: inline-block;
    cursor: pointer;
    margin-left: 3px;
    border: 1px solid rgb(116, 116, 116);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    transition: transform 150ms ease;

    &.reset {
      background-color: var(--card-background-color);
    }

    &:hover {
      transform: scale(1.2);
    }
  }
}

.create-issue-container {
  select {
    width: 100%;
    margin-bottom: 20px;
  }
}

.jira-description {
  margin-top: 10px;

  textarea {
    width: 100%;
    height: 200px;
  }
}

.member-jira-issue {
  margin-bottom: 10px !important;
}

.scheduled-communication {
  > * + * {
    margin-top: 0;
  }

  > div {
    opacity: 0;
  }

  .column {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .communication-actions {
    display: flex;
    align-items: center;

    button {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .edit-communication {
    display: inline-block;
    height: 23px;
    font-size: 14px;
    font-style: italic;
    color: #929292;
    cursor: pointer;
  }

  .updated {
    position: relative;

    &.even {
      animation: edited-highlight-1 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }

    &.odd {
      animation: edited-highlight-2 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    }
  }

  @keyframes edited-highlight-1 {
    0% {
      opacity: 1;
      top: 0;
    }
    50% {
      opacity: 0;
      top: -5px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }

  @keyframes edited-highlight-2 {
    0% {
      opacity: 1;
      top: 0;
    }
    50% {
      opacity: 0;
      top: -5px;
    }
    100% {
      opacity: 1;
      top: 0;
    }
  }
}

.ck-editor__editable {
  min-height: 30vh;

  ul {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

    li {
      margin: 0;
    }
  }
}

.details-container {
  .dropdown-menu {
    min-width: 13rem;
  }
}

.spar-highlight {
  color: var(--error-primary);
  font-weight: bold;
}

.profile-card-dropdown {
  position: absolute;
  top: 0;
  left: 0;
}

.edit-profile-image {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #bbbbbb;
  min-width: 30px !important;
  height: 30px !important;
  border-radius: 15px;
  border: 2px solid #ffffff;
}

.cropper-wrapper {
  position: relative;
  width: 100%;
  height: calc(100% - 4rem);
  margin-bottom: 10px;

  .button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    width: 40px;

    &:last-of-type {
      top: 60px;
    }
  }
}

.profile-image-upload__drop-target {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 10px;
  border: 2px dashed var(--primary);

  &.is-dragging {
    opacity: 0.5;
    border-width: 4px;
  }
}
