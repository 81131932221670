@import '../../../styles/breakpoints.scss';

.advanced-search {
  margin-top: 0;
  transition: margin-top 0.1s;

  h4 {
    margin-top: 0;
    font-weight: 500;
  }

  a {
    font-size: 15px;
    font-weight: bold;
  }

  .single-select__control,
  .multi-select__control {
    @include for-laptop-up {
      width: auto;
    }
  }

  .predefined-search {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
