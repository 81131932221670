.error-message-wrapper {
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
  margin-top: 20px;
}

.full-error-container {
  pre {
    background-color: #efefef;
    padding: 10px;
  }
}

.view-error {
  cursor: pointer;
  color: #000000;
}
