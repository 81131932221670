.skeleton {
  background-color: #d8d8d8;
  animation: 2s infinite pulse;
  color: #d8d8d8;
  margin-bottom: 5px;
}

@keyframes pulse {
  0% {
    opacity: 0.9;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 0.9;
  }
}
