@import '../../../styles/breakpoints.scss';

.search-results-container {
  position: relative;
  width: 100%;
  max-width: 1096px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 4em;
}

.search-results-count {
  text-align: center;
  font-size: 14px;
  font-style: italic;
  color: var(--search-results-count-color);
  margin-bottom: 10px;

  @include for-laptop-up {
    text-align: left;
  }
}

.search-results-table {
  background-color: var(--body-background);

  &.loading {
    opacity: 0.7;
  }

  a {
    text-decoration: none;
    color: unset;
  }

  @include for-laptop-up {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  }

  .member-icons-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, 30px);
    direction: rtl;

    .badge {
      display: inline-block;
      border-radius: 50%;
      position: absolute;
      color: #ffffff;
      width: 14px;
      height: 14px;
      font-size: 10px;
      top: -4px;
      right: -4px;
      text-align: center;
      font-weight: 800;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);

      &.open-support-issues {
        background-color: #ff991f;
      }

      &.closed-support-issues {
        background-color: #838383;
      }
    }
  }
}

:root[data-theme-variant='dark'] {
  .member-icons-container {
    .badge {
      color: #0747a6;
    }
  }
}

.row-button-container {
  display: block;
  text-align: right;

  .button-secondary {
    display: none;
  }

  .button {
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 115px;
    min-height: auto;
    padding: 7px 12px 10px 12px;
  }
}

.r-table-row:hover {
  .row-button-container .button-secondary {
    display: inline-flex;
  }
}
